import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import SiteMetadata from "../components/SiteMetadata"
import Cards from "../components/Cards"
import Carousel from "../components/Carousel"
import Layout from "../layouts/Layout"
import GoogleButton from "../components/GoogleButton"
import AppleButton from "../components/AppleButton"

export default props => {
  const {
    description,
    gallery,
    name,
    related,
    slug,
    summary,
    thumbnail,
    googlePlayUrl,
    appStoreUrl,
  } = props.data.item

  return (
    <Layout>
      <SiteMetadata
        title={name}
        description={summary}
        image={thumbnail.localFile.publicURL}
        slug={slug}
      />
      <div className="bg-gray-0 py-12 lg:py-16">
        <div className="container">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-2/3 pb-8">
              {gallery && gallery.length === 1 && (
                <Img
                  fluid={gallery[0].localFile.childImageSharp.fluid}
                  alt={name}
                />
              )}
              {gallery && gallery.length > 1 && <Carousel images={gallery} />}
            </div>
            <div className="w-full lg:w-1/3 lg:pl-8 xl:pl-12">
              <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-1">
                {name}
              </h1>
              <h2 className="text-xl leading-tight font-semibold tracking-tight text-blue-600 sm:text-2xl">
                {summary}
              </h2>
              {description && (
                <div className="my-4 text-base text-gray-700 whitespace-pre-line">
                  {description.description}
                </div>
              )}
              {(googlePlayUrl || appStoreUrl) && (
                <div className="flex justify-center">
                  {googlePlayUrl && googlePlayUrl !== "none" && (
                    <GoogleButton href={googlePlayUrl} />
                  )}
                  {appStoreUrl && appStoreUrl !== "none" && (
                    <AppleButton href={appStoreUrl} />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {related && (
        <div className="bg-gray-100 py-12 lg:py-16">
          <div className="container">
            <h2 className="text-3xl sm:text-4xl leading-tight font-extrabold tracking-tight text-gray-900 mb-8">
              You may also like
            </h2>
          </div>
          <Cards items={related} hideLastItemOnMobile={true} />
        </div>
      )}
    </Layout>
  )
}

export const query = graphql`
  query PortfolioItemQUery($slug: String!, $language: String!) {
    item: contentfulPortfolio(node_locale: { eq: $language }, slug: { eq: $slug }) {
      description {
        description
      }
      gallery {
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 960, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
      }
      name
      related {
        ...PortfolioCard
      }
      slug
      summary
      thumbnail {
        localFile {
          publicURL
        }
      }
      googlePlayUrl
      appStoreUrl
    }
  }
`
